import website from '../assets/website.png'
import email from '../assets/email.png'
import youtube from '../assets/youtube.png'
import github from '../assets/github.png'
import telegram from '../assets/telegram.png'
import twitter from '../assets/twitter.png'
import discord from '../assets/discord.png'


const items = [
{
    "title": "Help Portal",
    "subtitle": "Advice and answers from Pillar support team!",
    "image": website,
    "link": "https://bit.ly/3OpUy7B"
},
{
    "title": "Blog",
    "subtitle": "Read community,partnerships, product and smart wallet blog posts!",
    "image": website,
    "link": "https://bit.ly/3V1FhMM"
},
{
    "title": "GitHub",
    "subtitle": "Look at OpenSource magic :)",
    "image": github,
    "link": "http://bit.ly/3XxjwWP" //Github Profile link
},
{
    "title": "Twitter",
    "subtitle": "Let's connect on twitter!",
    "image": twitter,
    "link": "https://bit.ly/3EmM6Bn"// twitter profile link 
},
{
    "title": "Discord",
    "subtitle": "Let's chat about Pillar!",
    "image": discord    ,
    "link": "https://chat.pillar.fi" 
},
{
    "title": "YouTube",
    "subtitle": "We make videos!",
    "image": youtube,
    "link": "https://bit.ly/3EnykOR"//youtube channel link 
},
{
    "title": "Telegram",
    "subtitle": "Another communication platform?! Ok",
    "image": telegram,
    "link": "https://bit.ly/3XecDcJ" //Telegram Pofile 
},
{
    "title": "Mail",
    "subtitle": "Old way?! Let's e-mail!",
    "image": email,
    "link": "mailto:community@pillar.fi"
}]

export default items
